<template>
  <div>
    <div class="holder-comp">

          <div class="section section-left">

            <div class="textblock no-padding">
              <h2 class="headline-keyword">Medieninhaberin</h2>
              <p class="text-impressum">Amila Softić</p>
            </div>

            <div class="textblock no-padding">
              <h2 class="headline-keyword">Sitz des Medieninhabers</h2>
              <p class="text-impressum">Wien</p>
            </div>

            <div class="textblock no-padding">
              <h2 class="headline-keyword">Unternehmensgegenstand</h2>
              <p class="text-impressum justify">
                Psychotherapie, Psychologie, Psychiatrie, Supervision, Beratung. Die im Rahmen der Praxisgemeinschaft arbeitenden Einzelpersonen führen Ihre Dienstleistungen eigenveranwortlich und auf eigene Rechnung durch.
              </p>
            </div>

            <div class="textblock no-padding">
              <h2 class="headline-keyword">Konzept und Fotografie</h2>
              <p class="text-impressum"><a href="https://www.milasoftic.com/" target="blank">Amila Softić</a></p>
            </div>

            <div class="textblock no-padding">
              <h2 class="headline-keyword">Design und Programmierung</h2>
              <p class="text-impressum"><a href="https://www.mato.onl/" target="blank">Mato</a></p>
            </div>

            <div class="textblock no-padding-top">
              <h2 class="headline-keyword">Raumdesign</h2>
              <p class="text-impressum"><a href="http://www.wohndesign.at/" target="blank">Designstudio Schranzer</a></p>
            </div>

          </div>

    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Impressum | PRAXIS KOLLEKTIV WOLLZEILE',
  }
}
</script>

<style lang="scss" scoped>

  .textblock {
    width: 100%;

    &.no-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.no-padding-top {
      padding-top: 0;
    }

    .text-impressum {

      a {
        color: inherit;
      }

      &.justify {
        text-align: justify;
      }
    }
  }

</style>